// Angularのバージョンを15に上げたことで発生した不具合の修正

// バックドロップのZ座標の指定が必要になったので追加
ngb-modal-backdrop {
    z-index: 1050 !important;
}

// selectのデフォルトがappearance: noneになっていたので修正
.form-control {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}