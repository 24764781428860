// ================================================================================================
// 	File Name: timeline.scss
// 	Description: Timeline Page specific SCSS.
// 	----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 5 Admin Dashboard Template
//    Version: 3.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================
// Core mixins
@import "../bootstrap/functions";
@import "../bootstrap/mixins";
@import "../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../core/variables/bootstrap-variables";
@import "../bootstrap/variables";

@import "../core/variables/components-variables";

// Include horizontal time-line
@import "../plugins/timeline/timeline-horizontal";

/* Timeline */
.timeline {
	position: relative;
	display: block;
	margin: 0;
	padding: 10px 0;
	list-style: none;
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	&:before {
		content: " ";
		display: table;
	}
	>li {
		list-style: none;
		&:nth-child(even) {
			float: left;
			clear: left;
		}
		&:nth-child(odd) {
			float: right;
			clear: right;
			//margin-top: 20px;
		}
	}
	.media {
		border-bottom: 1px solid #eee;
		font-size: 13px;
		p {
			font-size: 13px;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}

.timeline-line+.timeline-item {
	margin-top: -20px;
}
.timeline-group {
	display: block;
	position: relative;
	margin: 20px 0;
	text-align: center;
	float: none !important;
	z-index: 1;
}
.timeline-poster {
	margin-top: -20px;
	.btn-link {
		color: #a1aab0;
		&:active {
			color: #3e5771;
		}
		&:focus {
			color: #3e5771;
		}
		&:hover {
			color: #3e5771;
		}
	}
	.btn-link.active {
		color: #3e5771;
	}
}

#timeline.timeline-center{
	.timeline-line {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		width: 2px;
		background-color: #ddd;
		transform: translate(-50%, 0);
	}
	.timeline-item {
		position: relative;
		display: inline-block;
		width: 50%;
		padding: 0 50px 35px;
		&:nth-child(even) {
			padding: 0px 35px 50px 0px;
			&:after {
				content: '';
				position: absolute;
				right: 19px;
				top: 10px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-left: 12px solid #ccc;
				z-index: 1;
				right: 24px;
				top: 20px;
				border-left: 11px solid #fff;
			}
			&:before {
				content: '';
				position: absolute;
				right: 23px;
				top: 20px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-left: 12px solid #ccc;
				z-index: 1;
			}
			>.timeline-badge {
				right: -20px;
			}
		}
		&:nth-child(odd) {
			padding: 0px 0px 50px 35px;
			&:after {
				content: '';
				position: absolute;
				left: 19px;
				top: 10px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				z-index: 1;
				left: 24px;
				top: 20px;
				border-right: 11px solid #fff;
			}
			&:before {
				content: '';
				position: absolute;
				left: 23px;
				top: 20px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				z-index: 1;
			}
			>.timeline-badge {
				left: -20px;
			}
		}
		>.timeline-badge {
			position: absolute;
			top: 12px;
			z-index: 1;
			>span {
				display: inline-block;
				width: 40px;
				height: 40px;
				// border: 1px solid #999;
				border-radius: 50%;
				text-align: center;
				// background-color: #fff;
				text-decoration: none;
				transition: all ease .3s;
				i{
					color: $white;
					font-size: 1.6rem;
					display: block;
				    line-height: 40px;
				    top: 0;
				}
				&:active {
					transform: scale(1.1);
				}
				&:focus {
					transform: scale(1.1);
				}
				&:hover {
					transform: scale(1.1);
				}
			}
			>a.active {
				transform: scale(1.1);
			}
		}
		>.timeline-panel {
			position: relative;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			&:hover {
				.timeline-actions {
					display: block;
				}
			}
		}
	}
}
#timeline.timeline-right{
	.timeline-line {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 12px;
		width: 2px;
		background-color: #ddd;
		transform: translate(-50%, 0);
	}
	.timeline-group{
		text-align: right;
	}

	.timeline-item {
		position: relative;
		display: inline-block;
		width: 100%;
		padding: 0px 43px 35px 0px;

			&:after {
				content: '';
				position: absolute;
				right: 33px;
				top: 21px;
				width: 0;
				height: 0;
				z-index: 1;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border:0;
				border-left: 11px solid #fff;
			}
			&:before {
				content: '';
				position: absolute;
				right: 32px;
				top: 20px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-left: 12px solid #fff;
				z-index: 1;
			}
			>.timeline-badge {
				right: -8px;
			}
		>.timeline-badge {
			position: absolute;
			top: 12px;
			z-index: 1;
			>span {
				display: inline-block;
				width: 40px;
				height: 40px;
				// border: 1px solid #999;
				border-radius: 50%;
				text-align: center;
				// background-color: #fff;
				text-decoration: none;
				transition: all ease .3s;
				i{
					color: $white;
					font-size: 1.6rem;
					display: block;
				    line-height: 40px;
				    top: 0;
				}
				&:active {
					transform: scale(1.1);
				}
				&:focus {
					transform: scale(1.1);
				}
				&:hover {
					transform: scale(1.1);
				}
			}
			>a.active {
				transform: scale(1.1);
			}
		}
		>.timeline-panel {
			position: relative;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			&:hover {
				.timeline-actions {
					display: block;
				}
			}
		}
	}
	.timeline-item.block {
		&:nth-child(even) {
			&:before {
				right: 8px;
				left: auto;
			}
			&:after{
				right: 11px;
				left: auto;
			}
		}
		&:nth-child(odd) {
			&:after {
				right: 8px;
				right: auto;
			}
		}
	}
}
#timeline.timeline-left{
	.timeline-line {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 12px;
		width: 2px;
		background-color: #ddd;
		transform: translate(-50%, 0);
	}
	.timeline-group{
		text-align: left;
	}
	.timeline-item {
		position: relative;
		display: inline-block;
		width: 100%;
		padding: 0 0 35px 43px;
			&:after {
				content: '';
				position: absolute;
				left: 33px;
				top: 21px;
				width: 0;
				height: 0;
				z-index: 1;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border: 0;
				border-right: 11px solid #fff;
			}
			&:before {
				content: '';
				position: absolute;
				left: 32px;
				top: 20px;
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #fff;
				z-index: 1;
			}
			>.timeline-badge {
				left: -8px;
			}

		>.timeline-badge {
			position: absolute;
			top: 12px;
			z-index: 1;
			>span {
				display: inline-block;
				width: 40px;
				height: 40px;
				// border: 1px solid #999;
				border-radius: 50%;
				text-align: center;
				// background-color: #fff;
				text-decoration: none;
				transition: all ease .3s;
				i{
					color: $white;
					font-size: 1.6rem;
					display: block;
				    line-height: 40px;
				    top: 0;
				}
				&:active {
					transform: scale(1.1);
				}
				&:focus {
					transform: scale(1.1);
				}
				&:hover {
					transform: scale(1.1);
				}
			}
			>a.active {
				transform: scale(1.1);
			}
		}
		>.timeline-panel {
			position: relative;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			&:hover {
				.timeline-actions {
					display: block;
				}
			}
		}
	}
}
#timeline .timeline-item.block {
	&:nth-child(even) {
		width: 100% !important;
		margin-top: 5px;
		&:after {
			left: 50%;
			right: auto;
			top: -11px;
			border: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid #fff;
			transform: translate(-50%, 0);
			/* top: -10px;
			border: 0;
			border-left: 11px solid $body-bg;
			border-right: 11px solid $body-bg;
			border-bottom: 11px solid #fff; */
		}
		&:before {
			left: 50%;
			right: auto;
			top: -11px;
			border: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid #ccc;
			transform: translate(-50%, 0);
		}
		>.timeline-badge {
			top: -28px;
			left: 50%;
			right: auto;
			transform: translate(-50%, 0);
		}
	}
	&:nth-child(odd) {
		width: 100% !important;
		margin-top: 5px;
		&:after {
			left: 50%;
			right: auto;
			top: -11px;
			border: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid #ccc;
			transform: translate(-50%, 0);
			top: -10px;
			border: 0;
			border-left: 11px solid $body-bg;
			border-right: 11px solid $body-bg;
			border-bottom: 11px solid #fff;
		}
		&:before {
			left: 50%;
			right: auto;
			top: -11px;
			border: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid #ccc;
			transform: translate(-50%, 0);
		}
		>.timeline-badge {
			top: -28px;
			left: 50%;
			right: auto;
			transform: translate(-50%, 0);
		}
	}
}
.timeline-actions {
	display: none;
}
.timeline-content {
	padding: 15px;
}
.timeline-footer {
	padding: 15px;
	border-top: 1px solid #eee;
	background-color: #fbfcfc;
	border-radius: 0 0 5px 5px;
}
.timeline-heading {
	padding: 15px;
	font-size: 20px;
}
.timeline-liveliness {
	padding: 15px;
	border-top: 1px solid #eee;
	background-color: #fbfcfc;
}
.timeline-heading+.timeline-content {
	padding-top: 0;
}
.timeline-date {
	font-size: 14px;
	color: #aaa;
}
.timeline-embed {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
	.embed-element {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	object {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.timeline-img {
	display: block;
	padding: 5px 0;
}
.timeline-img.first {
	margin-right: -10px;
}
.timeline-img.middle {
	margin-right: -10px;
	margin-left: -10px;
}
.timeline-img.last {
	margin-left: -10px;
}
.timeline-resume {
	border-top: 1px solid #eee;
	background-color: #fbfcfc;
}
.timeline-avatar {
	margin-top: -2px;
	margin-right: 10px;
}
.timeline-title {
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 1.42857;
	font-weight: 600;
	color: #3e5771;
	text-decoration: none;
	>small {
		display: block;
		font-size: 12px;
		line-height: 1.5;
		color: #a1aab0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.wrapkit-content-rtl {
	.timeline-avatar {
		margin-right: 0;
		margin-left: 10px;
	}
}
@include media-breakpoint-up(lg) {
	.timeline-item {
		&:nth-child(even) {
			padding: 0px 35px 50px 0px;
		}
		&:nth-child(odd) {
			padding: 0 0px 50px 35px;
			// margin-top: 50px;
		}
	}
}
@include media-breakpoint-down(md) {
	.timeline {
		padding-left: 15px;
	}
	.timeline-line {
		left: 15px;
	}
	.timeline-group {
		display: inline-block;
		margin-left: -22px;
	}
	.timeline-item {
		width: 100% !important;
		margin-top: 0 !important;
		padding-right: 10px;
		&:nth-child(even) {
			&:after {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
				left: 40px;
				top: 21px;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border-right: 11px solid #fff;
			}
			&:before {
				left: 39px;
				top: 20px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
			}
			>.timeline-badge {
				top: 12px;
				left: -20px;
				right: auto;
				transform: translate(0, 0);
			}
		}
		&:nth-child(odd) {
			&:after {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
				left: 40px;
				top: 21px;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border-right: 11px solid #fff;
			}
			&:before {
				left: 39px;
				top: 20px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
			}
			>.timeline-badge {
				top: 12px;
				left: -20px;
				right: auto;
				transform: translate(0, 0);
			}
		}
	}
	.timeline-item.block {
		&:nth-child(even) {
			padding-bottom: 0;
			&:after {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
				left: 20px;
				top: 11px;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border-right: 11px solid #fff;
			}
			&:before {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
			}
			>.timeline-badge {
				top: 12px;
				left: -6px;
				right: auto;
				transform: translate(0, 0);
			}
		}
		&:nth-child(odd) {
			padding-bottom: 0;
			&:after {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
				left: 20px;
				top: 11px;
				border-top: 11px solid $body-bg;
				border-bottom: 11px solid $body-bg;
				border-right: 11px solid #fff;
			}
			&:before {
				left: 19px;
				top: 10px;
				border: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid #ccc;
				transform: translate(0, 0);
			}
			>.timeline-badge {
				top: 12px;
				left: -6px;
				right: auto;
				transform: translate(0, 0);
			}
		}
	}
}
@include media-breakpoint-down(md) {
	#timeline.timeline-wrapper{
		.timeline {
			padding: 0;
			.card-body{
				text-align:left;
			}
		}
		.timeline-line {
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 2px;
			background-color: #ddd;
			transform: translate(-50%, 0);
		}
		.timeline-group {
			display: block;
			margin-left: -6px;
			text-align: center;
		}
		.timeline-item {
			display: block;
			padding: 20px 0 20px !important;
			&:nth-child(even) {
				float: none;
				clear: both;
				&:after {
					display: none;
				}
				&:before {
					display: none;
				}
			}
			&:nth-child(odd) {
				float: none;
				clear: both;
				&:after {
					display: none;
				}
				&:before {
					display: none;
				}
			}
			>.timeline-badge {
				top: 0px !important;
	    		left: 45% !important; //47
			}
		}
		.timeline-item.block {
			&:nth-child(even) {
				float: none;
				clear: both;
				&:after {
					display: none;
				}
				&:before {
					display: none;
				}
			}
			&:nth-child(odd) {
				float: none;
				clear: both;
				&:after {
					display: none;
				}
				&:before {
					display: none;
				}
			}
		}
		.timeline-item.timeline-poster {
			>.timeline-badge {
				display: none;
			}
		}
	}
}
@include media-breakpoint-down(xs) {
	.timeline-item {
		>.timeline-badge {
			top: 0px !important;
    		left: 44% !important;
		}
	}
}
